import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <main className="contact-content">
        <div className="contact-box">
            <div className="contact-title"><h3>Contact</h3></div>
                <div className="contact-blurb">
                  <div>I'm always happy to discuss opportunities for speaking, research collaboration, and public communication.  My primary area of expertise is the intersection between national security and machine learning.</div>
                </div>
                <div className="contact-blurb">Academically, my AI research interests include Transformer language models, diffusion models, large-scale model training, defense against online influence campaigns, and ethical/adversarial robustness.</div>
                <div className="contact-email">
                  By email, you can reach me at <a href="mailto:evan.crothers@outlook.com">evan.crothers@outlook.com</a>.
                </div>
                <div className="contact-twitter">
                  On social media, you can find me on <a href="https://twitter.com/ecrws">Twitter</a> and <a href="https://www.linkedin.com/in/evan-crothers">LinkedIn</a>.
                </div>
                <img className="contact-image" src='bio_files/2019_AI_Society_Summer_Institute_Amii_group.jpg' alt="AMII Institute on AI and Society"/>
        </div>
    </main>
  );
}

export default Contact;
