import React from 'react';
import Socials from './Socials';
import Bio from './Bio';
import PhotoList from './PhotoList';
import './BioContent.css';

function BioContent() {
  return (
    <main className="content">
        <Bio/>
        <PhotoList/>
        <div className="contact-banner">
            <div>
                <div>
                  <div>
                    <h3>Get in Touch</h3>
                    <Socials/>
                  </div>
                </div>
            </div>
        </div>
    </main>
  );
}

export default BioContent;
