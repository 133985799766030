import React from 'react';
import './Speaking.css';


// Could include photos from AI Institute on AI and Society, sitting with Richard S. Sutton
function Speaking() {
  return (
    <main className="speaking-content">
        <div className="speaking-box">
            <div className="speaking-title"><h3>Speaking</h3></div>
                <div>
                  During his time with government, Dr. Evan Crothers has spoken at numerous security-focused government conferences across Europe and North America, on the topics of machine learning, cybersecurity, online extremism and data science.
                </div>
                <div className="academ-preface">
                  As part of his academic public-facing research, Dr. Crothers has presented as an invited speaker on the United Nations ``AI for Good" platform speaking about the cybersecurity risks of large language model abuse.  Dr. Crothers has also presented at the following conferences:
                </div>
                <ul className="conf-list">
                  <li>10th International Conference on machine Learning, Optimization and Data science (LOD), 2024</li>
                  <li>AAAI Conference on Artificial Intelligence, 2023</li>
                  <li>International Joint Conference on Neural Networks (IJCNN), 2022</li>
                  <li>Computational Data and Social Networks: 10th International Conference, CSoNet 2021, Virtual, 2021*</li>
                  <li>Vector Institute Invited Featured Alumni - Virtual, 2020</li>
                  <li>IEEE Machine Learning for Signal Processing (MLSP) - Pittsburgh, 2019*</li>
                  <li>CIFAR Summer Institute on AI and Society - Edmonton, 2019</li>
                  <li>ASA Quality and Productivity Research Conference (QPRC) - Washington DC, 2019*</li>
                  <li>IEEE International Conference on Robotics and Automation (ICRA) - Seattle, 2015 <sup>&#8224;</sup></li>
                  <li>IEEE International Conference on Robotics and Automation (ICRA) - Hong Kong, 2014 <sup>&#8224;</sup></li>
                </ul>
                <div className="smallfont">
                    * scheduled talk &nbsp;&nbsp; &#8224; technical demonstration
                </div>
        </div>
        <div className="logo-box">
            <img className="logo-image" src='bio_files/UN_emblem_blue.svg' alt="United Nations"/>
            <img className="logo-image" src='bio_files/aaai_2024.jpg' alt="AAAI 2024"/>
            <img className="logo-image" src='bio_files/amii.png' alt="AMII"/>
            <img className="logo-image" src='bio_files/vector.jpg' alt="Vector"/>
            <img className="logo-image" src='bio_files/uottawa.png' alt="University of Ottawa"/>
            <img className="logo-image" src='bio_files/AU_stacked_logo.png' alt="American University"/>
            <img className="logo-image" src='bio_files/ieee.png' alt="IEEE"/>
        </div>
    </main>
  );
}

export default Speaking;
