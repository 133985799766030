import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import tileData from '../tileData';

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1260px',
    margin: '2em auto 0 auto',
  },
  root: {
    padding: 20,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 850,
    height: '100%',
    transform: 'translateZ(0)',
  },
  titleBar: {
    textAlign: 'start',
    fontFamily: 'Zilla Slab,Lora,Georgia,Times,serif',
    fontSize: '24px !important',
    fontWeight: 600,
    fontStyle: 'italic',
    cursor: 'pointer',
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  tileImg: {
    cursor: 'pointer',
  },
  title: {
    fontSize: '24px',
  },
  icon: {
    color: 'white',
  },
}));

export default function PhotoList() {
  const classes = useStyles();
  const history = useHistory();

  function handleNav(path) {
    history.push("/" + path);
  }

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <GridList cellHeight={200} spacing={12} className={classes.gridList}>
          {tileData.map((tile) => (
              <GridListTile key={tile.img} cols={tile.featured ? 2 : 1} rows={tile.featured ? 2 : 1}>
                <img className={classes.tileImg} src={tile.img} alt={tile.title} onClick={() => handleNav(tile.link)} />
                <GridListTileBar
                  title={tile.title}
                  titlePosition="bottom"
                  actionPosition="left"
                  className={classes.titleBar}
                  classes={{
                    title: classes.title
                  }}
                  onClick={() => handleNav(tile.link)} 
                />
              </GridListTile>
          ))}
        </GridList>
      </div>
    </div>
  );
}