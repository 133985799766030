import React from 'react';
import './Career.css';

// Additional Blizzard ref URL
// "https://web.archive.org/web/20160206103344/https://us.battle.net/sc2/en/blog/8475171"
function Career() {
  return (
    <main className="career-content">
        <div className="career-box">
            <div className="career-title"><h3>Career</h3></div>
                <div className="career-title"><h4>Industry and Government</h4></div>
                <div className="career-blurb">
                  Dr. Evan Crothers is an senior AI strategist, developer, and researcher, designing ethical and effective systems to safeguard Canada.  During his time in Public Safety Canada, he was the recipient of the “Director’s Merit Award”, the highest departmental honour awarded for contribution to public safety.
                  He has further been recognized as a key contributor to the Security and Intelligence Threats to Election (SITE) Task Force in support of the G7 Rapid Response Mechanism (RRM), protecting G7 democracies from threats to elections.  As an academic, Dr. Crothers speaks at the intersection of AI and cybersecurity, including as part of the United Nations "AI for Good" platform.
                </div>
                <div className="career-blurb vspace">
                  At the time of writing, Dr. Crothers works as a senior consultant with AWS Professional Services, providing AI guidance to the Government of Canada.  There he cultivates teams, mentoring the next generation of builders, and gets hands-on with some of the most critical systems in the country.
                </div>
                <hr className="vspace"/>
                <div className="career-title vspace"><h4>Academic</h4></div>
                <div className="career-blurb">
                  Concurrently with his award-winning work for the Canadian federal government, Dr. Crothers attained his <i>PhD in Computer Science</i> and <i>Masters of Computer Science - Concentration in Applied AI</i> from the University of Ottawa,
                  publishing research independently in addition to working full-time as a software engineer.  His PhD work focused on large language models, the same technology that is heavily leveraged for current generative AI applications, and has spoken at venues including AAAI and the United Nations.  His Master's thesis research on ethically-rigorous detection methodologies for online influence campaigns received first place for "Technology for the Digital Transformation of Society" in competition.
                </div>
                <div className="career-blurb vspace">
                  Dr. Crothers received his <i>Bachelor in Software Engineering - Minor in Cognitive Science</i> from University of Waterloo, where he led the programming division of award-winning microrobotics team University of Waterloo Nano Robotics Group (UWNRG), and served as Professional Engagement Lead for <i>Engineers Without Borders</i> uWaterloo.
                </div>
                <hr className="vspace"/>
        </div>
    </main>
  );
}
/**
                <h4 className="vspace">Passion Projects</h4>
                <div className="career-title"><h5>E-Sports</h5></div>
                <div className="career-blurb">
                    In 2011, Dr. Evan Crothers, along with co-founders Ali Vira and William Dahlström, started "GLHF Magazine": a StarCraft-focused e-sports publications that ran until 2014.
                        During this time it was the largest e-sports magazine in the world -- with a combined crew of 35 writers, editors, and designers,
                        and over 1,000,000 combined digital reads.  The magazine featured news and interviews with top players and community celebrities worldwide, and was a mainstay of the StarCraft community.
                        Issue releases became <a href="https://web.archive.org/web/20160206005325/https://us.battle.net/sc2/en/blog/9623408">featured prominently by game publisher Blizzard</a>.
                </div>
                <div className="career-title vspace"><h5>Viral Game Development</h5></div>
                <div className="career-blurb">
                    With co-developer Graham Hutchison, Dr. Crothers has published two short horror games set in the Canadian wilderness: "Cozy" and its spirirtual successor "Shiver".
                    Both games have thousands of downloads, and each has been the subject of multi-million-view "Let's Play" videos by high-profile YouTube creators, including&nbsp;
                    <a href="https://en.wikipedia.org/wiki/Markiplier">Markiplier</a> and <a href="https://en.wikipedia.org/wiki/Jacksepticeye">Jacksepticeye</a>.
                </div>
*/

export default Career;
