import React from 'react';
import { IconButton } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

import { withStyles }  from '@material-ui/core/styles';

const EmailButton = withStyles((theme) => ({
    root: {
      padding: '8px',
      marginRight: '8px',
      marginTop: '12px',
      color: 'white',
      backgroundColor: 'grey',
      '&:hover': {
        backgroundColor: 'lightgrey',
        color: 'white',
      },
    },
  }))(IconButton);

const LinkedInButton = withStyles((theme) => ({
    root: {
      padding: '8px',
      marginTop: '12px',
      marginRight: '8px',
      color: 'white',
      backgroundColor: '#0a66c2',
      '&:hover': {
        backgroundColor: '#1a76d2',
        color: 'white',
      },
    },
  }))(IconButton);

const TwitterButton = withStyles((theme) => ({
    root: {
      padding: '8px',
      marginTop: '12px',
      marginRight: '8px',
      color: 'white',
      backgroundColor: '#1da1f2',
      '&:hover': {
        backgroundColor: '#8bcff8',
        color: 'white',
      },
    },
  }))(IconButton);

const GitHubButton = withStyles((theme) => ({
    root: {
      padding: '8px',
      marginTop: '12px',
      color: 'white',
      backgroundColor: '#211F1F',
      '&:hover': {
        backgroundColor: '#615F5F',
        color: 'white',
      },
    },
  }))(IconButton);

function Socials() {
    return (<div className="social">
        <EmailButton aria-label="email" size="medium" href="mailto:evan.crothers@outlook.com">
            <EmailIcon fontSize="small" />
        </EmailButton>
        <LinkedInButton aria-label="linkedin" size="medium" href="https://www.linkedin.com/in/evan-crothers">
            <LinkedInIcon fontSize="small" />
        </LinkedInButton>
        <TwitterButton aria-label="twitter" size="medium" href="https://twitter.com/ecrws">
            <TwitterIcon fontSize="small" />
        </TwitterButton>
        <GitHubButton aria-label="github" size="medium" href="https://github.com/ecrows">
            <GitHubIcon fontSize="small" />
        </GitHubButton>
    </div>);
}

export default Socials;