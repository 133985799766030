const tileData = [
    {
      img: 'bio_files/collage.png',
      title: 'Research Publications',
      link: 'publications',
      featured: true,
    },
    {
      img: 'bio_files/speaking_dc_wide.jpg',
      title: 'Speaking',
      link: 'speaking',
      featured: true,
    },
    {
      img: 'bio_files/desk.png',
      title: 'Career',
      link: 'career',
      featured: false,
    },
    {
      img: 'bio_files/discussions.jpg',
      title: 'Contact',
      link: 'contact',
      featured: false,
    },
  ];
  
  export default tileData;