import React from 'react';
import './Publications.css';

function Publications() {
  return (
    <main className="publications-content">
        <div className="pub-box">
            <div className="pub-title"><h3>Publications</h3></div>
            <div className="pub-blurb">
                Dr. Evan Crothers has authored and co-authored research publications presented at AI conferences, academic institutions, and government offices around the world.
                For an up-to-date list of his public-facing work, please see his <a href="https://scholar.google.com/citations?user=eQX0trUAAAAJ">Google Scholar profile</a>.
            </div>
            <div className="pub-list-heading">
                Some of his recent work includes:
            </div>
            <div className="pub-list">
                <ul>
                    <li>
                        Machine Generated Text: A Comprehensive Survey of Threat Models and Detection Methods*
                        <div className="pub-authors">with Dr. Nathalie Japkowicz and Dr. Herna Viktor</div>
                    </li>
                    <li>
                        Adversarial Robustness of Neural-Statistical Features in Detection of Generative Transformers*
                        <div className="pub-authors">with Dr. Nathalie Japkowicz and Dr. Herna Viktor</div>
                    </li>
                    <li>
                        Mean User-Text Agglomeration (MUTA): Practical User Representation and Visualization for Detection of Online Influence Operations*
                        <div className="pub-authors">with Dr. Herna Viktor and Dr. Nathalie Japkowicz</div>
                    </li>
                    <li>
                        Ethical Detection of Online Influence Campaigns Using Transformer Language Models*
                        <div className="pub-authors">Master's Thesis - Master of Computer Science, Concentration in Applied AI</div>
                    </li>
                    <li>
                        Towards the Ethical Detection of Online Influence Campaigns*
                        <div className="pub-authors">with Dr. Nathalie Japkowicz and Dr. Herna Viktor</div>
                    </li>
                    <li>Independent Component Analysis for Trustworthy Cyberspace during High-Impact Events: An Application to COVID-19
                        <div className="pub-authors">with Dr. Zois Boukouvalas, Dr. Christine Mallinson, et al.</div>
                    </li>
                    <li>Siri Humphrey: Design Principles for an AI Policy Analyst<sup>&#8224;</sup>
                        <div className="pub-authors">with Michael Karlin, Dr. Justin Longo, Trooper Sanders, et al.</div>
                    </li>
                    <li>Could AI Drive Transformative Social Progress? What Would This Require?
                        <div className="pub-authors">with Dr. Edward Parson, Dr. Robert Lempert, et al.</div>
                    </li>
                </ul>
            </div>
        <div className="smallfont">
            * primary or sole author &nbsp;&nbsp; &#8224; equal contribution
        </div>
        </div>
    </main>
  );
}

export default Publications;
