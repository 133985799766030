import React from 'react';
import './App.css';
import './fonts.css';
import Header from './Header';
import Footer from './Footer';
import BioContent from './Home/BioContent';
import Publications from './Publications/Publications';
import Career from './Career/Career';
import Speaking from './Speaking/Speaking';
import Contact from './Contact/Contact';
import ScrollToTop from './ScrollToTop';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div>
          <Switch>
            <Route path="/contact">
              <Header withHome={true}/>
              <Contact/>
            </Route>
            <Route path="/speaking">
              <Header withHome={true}/>
              <Speaking/>
            </Route>
            <Route path="/career">
              <Header withHome={true}/>
              <Career/>
            </Route>
            <Route path="/publications">
              <Header withHome={true}/>
              <Publications/>
            </Route>
            <Route path="/">
              <Header withHome={false}/>
              <BioContent/>
            </Route>
          </Switch>
        </div>
        <Footer/>
      </ScrollToTop>
    </Router>
  );
}

export default App;
