import React from 'react';
import './Bio.css';

import Socials from './Socials';

function Bio() {
  return (
    <div className="bio">
      <div className="bio-body">
        <div className="bio-img">
          <img className="bio-photo" src="bio_files/evan_crothers_bio.png" alt="Evan Crothers"/>
        </div>
        <div className="bio-description">
          <span className="bio-heading"><h3>Evan Crothers, PhD</h3>
          <img style={{"alignSelf": "center", "marginLeft": "0.5em"}} src="bio_files/maple_leaf.svg" alt="maple leaf" height="32" width="32"/>
          <img style={{"alignSelf": "center", "marginLeft": "0.5em"}} src="bio_files/pride.svg" alt="pride flag" height="32" width="32"/></span> 
          <div className="bio-content"><em>Dr. Evan Crothers is a senior applied AI consultant, developer, and researcher designing ethical and effective systems to safeguard Canada.  For his work in federal government he received the “Director’s Merit Award”, the highest departmental honour for protection of public safety.   As an academic, Dr. Crothers speaks at the intersection of AI and cybersecurity, including as part of the United Nations "AI for Good" platform.</em>
            <Socials/>
          </div>
        </div>
      </div>
    </div>
  );
  }

export default Bio;
