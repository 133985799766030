import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div>
        <div className="footer-logo">
            <img src="bio_files/pixel_crow.png" style={{"maxWidth": "48px"}} alt="ecrows logo"/>
        </div>
        <div className="footer">
          <div className="yaydocker">Hosted in beautiful Calgary, Alberta.</div>
          <div className="copyright"><i>© 2024 Evan Crothers</i></div>
        </div>
    </div>
  );
}

export default Footer;