import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";
import HomeIcon from '@material-ui/icons/Home';

function Header(props) {
  return (
          <header className="header">
            <nav className="main">
                <div className="header-container">
                  <div className="inner left">
                    <div className="logo">
                      <Link to="/" className="logo-link">
                        <img src="bio_files/pixel_crow.png" style={{"maxWidth": "48px"}} alt="ecrows logo"/>
                      </Link>
                    </div>
                    {props.withHome && <div className="home-box">
                      <Link to="/">
                        <span className="home-link menu-item"><HomeIcon/></span>
                      </Link>
                    </div>}
                  </div>
                    <div className="inner right">
                    <div className="links">
                      <ul className="link-list">
                            <li className="menu-item">
                              <Link to="/career">
                                Career
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/publications">
                                Publications
                              </Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/speaking">
                                Speaking
                              </Link>
                            </li>
                            <li className="menu-item highlight">
                              <Link to="/contact">
                                  <span className="text-highlight">Contact</span>
                              </Link>
                            </li>
                      </ul>
                    </div>
                    </div>
                  </div>
            </nav>
      </header>
  );
  }

export default Header;